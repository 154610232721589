import React from "react";

function RecordsManagement() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <mask
                id="mask0_343_4237"
                style={{ maskType: "alpha" }}
                width="30"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30V30H0z"></path>
            </mask>
            <g fill="#DF8D46" mask="url(#mask0_343_4237)">
                <path d="M21.116 23.308v3.78l.037-.036 4.68-4.674c.356-.351.62-.784.77-1.26h-3.297a2.187 2.187 0 00-2.19 2.19z"></path>
                <path d="M26.774 6.435A4.438 4.438 0 0022.34 2H7.657a4.438 4.438 0 00-4.435 4.435v17.13A4.438 4.438 0 007.657 28h11.22c.085 0 .17-.006.25-.012v-4.68a4.18 4.18 0 014.179-4.179h3.468V6.435zm-10.552 13H8.88a.765.765 0 110-1.53h7.342a.764.764 0 110 1.53zm4.894-4.894H8.88a.765.765 0 010-1.53h12.236a.765.765 0 010 1.53zm0-4.894H8.88a.765.765 0 010-1.53h12.236a.765.765 0 010 1.53z"></path>
            </g>
        </svg>
    );
}

export default RecordsManagement;
