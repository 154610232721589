import React from "react";

function Share() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <mask
                id="mask0_340_4178"
                style={{ maskType: "alpha" }}
                width="30"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30V30H0z"></path>
            </mask>
            <g mask="url(#mask0_340_4178)">
                <path
                    fill="#DF8D46"
                    d="M22.245 20.092a4.26 4.26 0 00-2.762 1.01l-7.84-4.306c.208-.505.327-1.07.327-1.634a4.22 4.22 0 00-.327-1.633l8.137-4.544a4.313 4.313 0 002.436.772 4.278 4.278 0 000-8.553c-2.347 0-4.277 1.93-4.277 4.277 0 .802.208 1.514.594 2.168l-7.9 4.395a4.285 4.285 0 00-2.94-1.158 4.277 4.277 0 000 8.553 4.34 4.34 0 002.94-1.159l7.752 4.277a4.277 4.277 0 003.86 6.118c2.346 0 4.277-1.93 4.277-4.277 0-2.346-1.93-4.306-4.277-4.306z"
                ></path>
            </g>
        </svg>
    );
}

export default Share;
