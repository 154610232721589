import React from "react";

function Microsoft() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <mask
                id="mask0_340_4184"
                style={{ maskType: "alpha" }}
                width="30"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30V30H0z"></path>
            </mask>
            <g fill="#DF8D46" mask="url(#mask0_340_4184)">
                <path d="M2 2h12.046v12.046H2V2zM15.953 2H28v12.046H15.953V2zM2 15.954h12.046V28H2V15.954zM15.953 15.954H28V28H15.953V15.954z"></path>
            </g>
        </svg>
    );
}

export default Microsoft;
