import React, { ReactElement } from "react";
import colors from "@styles/variables/contentintelligenceVariables.module.scss";
import styles from "./styles.module.scss";
import Share from "../svgs/share";
import Microsoft from "../svgs/microsoft";
import RecordsManagement from "../svgs/recordsManagement";
import RoboticAutomation from "../svgs/roboticAutomation";
import SmartInvoices from "../svgs/smartInvoice";
import ElectronicForms from "../svgs/electronicForms";
import Bars from "../svgs/bars";

export default function ProductiveTools(): ReactElement {
    const list = [
        {
            title: "Direct Share",
            description:
                "Easily and securely share documents outside of your organization and support governance with a tool that provides the ability to email documents with password-protected links and automatic expiration dates.",
            icon: <Share />,
        },
        {
            title: "Microsoft 365 Integration",
            description:
                "Minimize context-switching and streamline workflows by creating, editing, routing and archiving your Microsoft 365 documents in Laserfiche.",
            icon: <Microsoft />,
        },
        {
            title: "Records Management",
            description:
                "Get unparalleled 360-degree view of records in a records management platform guided by DoD 5015.2 principles, to help strengthen compliance across all content and processes.",
            icon: <RecordsManagement />,
        },
        {
            title: "Robotic Process Automation",
            description:
                "Utilize intuitive technology that compromises of bots and programs that can perform repetitive tasks right from a user interface. RPA is fast to deploy, easy to update, and is a powerful catalyst for system integrations.",
            icon: <RoboticAutomation />,
        },
        {
            title: "Smart Invoice Capture",
            description:
                "Boost your accounts payable process turnaround time by leveraging a machine learning-driven intelligent capture tool, adept at effortlessly extracting data from invoices in any format.",
            icon: <SmartInvoices />,
        },
        {
            title: "Electronic Forms",
            description:
                "Create digital-first experiences with web-based forms that make it easy to capture information from clients, constituents or students — then kickstart an automated approvals or review process — with low coding required.",
            icon: <ElectronicForms />,
        },
        {
            title: "Reporting and Analytics",
            description:
                "Make more informed decisions with data-driven insights when you discover data trends with custom reporting tools and built-in analytics charts.",
            icon: <Bars />,
        },
    ];

    return (
        <section className={`${styles.container} flex flex-col my-20`}>
            <h2
                style={{
                    color: colors.orange,
                }}
                className="text-center  font-bold text-xl lg:text-4xl mt-20"
            >
                Enhance Efficiency and Productivity with an Expanded Toolkit
            </h2>
            <p className="text-center mb-16 md:mb-24 text-base md:text-lg">
                Accelerate business progress smoothly and swiftly with these
                empowering tools.
            </p>
            <div className="grid grid-cols-1 gap-16 lg:grid-cols-2 mb-20">
                {list.map((item, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-start gap-4 md:gap-8"
                    >
                        <div className="flex items-center gap-4">
                            <div>{item.icon}</div>
                            <h3 className="text-lg md:text-2xl">
                                {item.title}
                            </h3>
                        </div>
                        <p className="text-sm md:text-base">
                            {item.description}
                        </p>
                    </div>
                ))}
            </div>
        </section>
    );
}
