import React from "react";

function Bars() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <mask
                id="mask0_340_4215"
                style={{ maskType: "alpha" }}
                width="30"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30V30H0z"></path>
            </mask>
            <g fill="#DF8D46" mask="url(#mask0_340_4215)">
                <path d="M4.738 18.032a3.625 3.625 0 00-3.621 3.621v2.459a3.62 3.62 0 107.238 0v-2.459a3.623 3.623 0 00-3.617-3.62zM15 10.84a3.625 3.625 0 00-3.621 3.62v9.666a3.62 3.62 0 107.238 0V14.46a3.624 3.624 0 00-3.618-3.621zM25.262 2a3.623 3.623 0 00-3.618 3.617v18.509a3.62 3.62 0 107.238 0V5.617A3.623 3.623 0 0025.262 2z"></path>
            </g>
        </svg>
    );
}

export default Bars;
