import React from "react";

function ElectronicForms() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <mask
                id="mask0_340_4207"
                style={{ maskType: "alpha" }}
                width="30"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30V30H0z"></path>
            </mask>
            <g
                fill="#DF8D46"
                fillRule="evenodd"
                clipRule="evenodd"
                mask="url(#mask0_340_4207)"
            >
                <path d="M17.871 6.333a.875.875 0 00-.883-.867h-3.975a.875.875 0 00-.883.867v17.333c0 .479.395.867.883.867h3.975a.875.875 0 00.883-.867V6.333zm-3.975.867v15.6h2.209V7.2h-2.209z"></path>
                <path d="M26.705 5.467C26.705 3.552 25.123 2 23.17 2h-3.533c-1.951 0-3.533 1.552-3.533 3.467v19.066c0 1.915 1.582 3.467 3.533 3.467h3.533c1.952 0 3.534-1.552 3.534-3.467V5.467zm-5.3 17.224c.487 0 .883.389.883.867a.876.876 0 01-.883.867.875.875 0 01-.884-.867c0-.478.396-.866.884-.866zm0-3.033c.487 0 .883.389.883.867a.875.875 0 01-.883.867.875.875 0 01-.884-.867c0-.478.396-.867.884-.867zm0-3.033c.487 0 .883.388.883.866a.875.875 0 01-.883.867.875.875 0 01-.884-.867c0-.478.396-.866.884-.866zM20.52 6.333v8.125c0 .479.396.867.884.867a.876.876 0 00.883-.867V6.333a.875.875 0 00-.883-.866.875.875 0 00-.884.866zM13.896 5.467C13.896 3.552 12.314 2 10.363 2H6.829C4.878 2 3.296 3.552 3.296 5.467v19.066C3.296 26.448 4.878 28 6.829 28h3.534c1.95 0 3.533-1.552 3.533-3.467V5.467zm-5.3 17.224c.488 0 .883.389.883.867a.875.875 0 01-.883.867.875.875 0 01-.883-.867c0-.478.395-.866.883-.866zm0-3.033c.488 0 .883.389.883.867a.875.875 0 01-.883.867.875.875 0 01-.883-.867c0-.478.395-.867.883-.867zm0-3.033c.488 0 .883.388.883.866a.875.875 0 01-.883.867.875.875 0 01-.883-.867c0-.478.395-.866.883-.866zM7.713 6.333v8.125c0 .479.395.867.883.867a.875.875 0 00.883-.867V6.333a.875.875 0 00-.883-.866.875.875 0 00-.883.866z"></path>
            </g>
        </svg>
    );
}

export default ElectronicForms;
