import React from "react";

function RoboticAutomation() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="none"
            viewBox="0 0 30 30"
        >
            <mask
                id="mask0_340_4195"
                style={{ maskType: "alpha" }}
                width="30"
                height="30"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H30V30H0z"></path>
            </mask>
            <g mask="url(#mask0_340_4195)">
                <path
                    fill="#DF8D46"
                    d="M28.346 13.397h-2.113V8.606a.895.895 0 00-.893-.893h-8.659V3.577c0-.863-.684-1.577-1.577-1.577-.862 0-1.577.714-1.577 1.577v4.136H4.868a.895.895 0 00-.892.893v4.79H1.893A.895.895 0 001 14.29v6.577c0 .505.387.892.893.892h2.083v4.79c0 .477.386.894.892.894H25.37a.895.895 0 00.893-.893v-4.761h2.113a.895.895 0 00.893-.893v-6.606c-.03-.476-.447-.893-.923-.893zM7.963 14.438c0-1.369 1.1-2.47 2.47-2.47a2.464 2.464 0 012.47 2.47c0 1.369-1.101 2.47-2.47 2.47-1.37-.03-2.47-1.101-2.47-2.47zm11.992 8.302a.895.895 0 01-.893.893h-7.945a.915.915 0 01-.893-.893v-.982c0-.476.417-.893.893-.893h7.945c.476 0 .893.417.893.893v.982zm-.179-5.862c-1.369 0-2.47-1.1-2.47-2.47 0-1.369 1.101-2.47 2.47-2.47 1.369 0 2.47 1.101 2.47 2.47a2.445 2.445 0 01-2.47 2.47z"
                ></path>
            </g>
        </svg>
    );
}

export default RoboticAutomation;
